import React from 'react'
import { FaWhatsapp } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { CgWebsite } from "react-icons/cg";
import logo from '../assets/gomacoding.png'
import { Link } from 'react-router-dom';
import powered from '../assets/PoweredbyGoma.png'

const Goma_tech = () => {

  return (
    <div className='flex flex-col justify-center gap-20 items-center h-screen bg-[#e2e8f0]'>
    <div className=" fixed  w-screen h-screen  top-0 left-0 right-0 -z-10   bg-[#e2e8f0]"></div>

        <div className='flex flex-col justify-center items-center'>
        
        <div className=" w-56 h-56 rounded-full p-3 py-4 mt-10  bg-[#fff] flex justify-center items-center"    
                 style={{ boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",}}>
             <img src={logo} />
             </div>
              <h2 className=' text-xl font-bold mt-3'>Gomaksa</h2>
            <h2 className='text-sm text-[#a1a1aa] text-center w-[80%]'>Hit us up, and let's paint the town with the colors of your vision. </h2>
             </div>

            <div className='grid grid-cols-1 md:grid-cols-2 text-black w-screen'>
        <a href='https://www.instagram.com/gomaksa?igsh=d2g1cXh6YTJ6a2F6' target='_blank'>
        <div className='flex justify-between mr-1 rounded-lg p-3 bg-[#fff] relative m-2 w-[95%]'>
                <div className=' flex items-center'>
                    <div className='absolute text-xl left-4'><FaInstagram size={30}/></div></div>
                <div className='flex justify-center items-center text-center w-full'>
                <h2 className='font-semibold text-sm'>Instagram</h2>
                </div>
        </div>
        </a>

        <a href='https://www.gomaksa.com/' target='_blank'>
         <div className='flex justify-between mr-1 rounded-lg p-3 bg-[#fff] relative m-2 w-[95%]'>
                <div className=' flex items-center'>
                    <div className='absolute text-xl left-4'><CgWebsite size={30}/></div></div>
                <div className='flex justify-center items-center text-center w-full'>
                <h2 className='font-semibold text-sm'>Website</h2>
                </div>
        </div>
        </a>

        <button
        onClick={() => {
    const phoneNumber = "966540269079"; // Replace with your specific phone number
    const cleanedPhoneNum = phoneNumber.replace(/\s+/g, ""); // Remove all spaces
    window.open(`https://wa.me/${cleanedPhoneNum}`);
  }}
        >
         <div className='flex justify-between mr-1 rounded-lg p-3 bg-[#fff] relative m-2 w-[95%]'>
                <div className=' flex items-center'>
                    <div className='absolute text-xl left-4'><FaWhatsapp size={30}/></div></div>
                <div className='flex justify-center items-center text-center w-full'>
                <h2 className='font-semibold text-sm'>Whatsapp</h2>
                </div>
        </div> 
        </button>

      </div>
      <a href="https://www.gomaksa.com/" className="cursor-pointer" target="_blank"> <img src={powered} alt="Powerd By Goma" className='w-24 h-auto mx-auto '/></a>

    </div>

  )
}

export default Goma_tech
