/* eslint-disable */
import React, { useState, useEffect, useRef } from 'react';
import logo from '../assets/lemaisonmmg.png';
import powered from '../assets/11 PBGoma.svg';
import male from '../assets/linkedin (2).png';

import { IoMdCall } from "react-icons/io";
import { IoLogoWhatsapp } from "react-icons/io";
import { TbMailFilled } from "react-icons/tb";
import { BsGlobe2 } from "react-icons/bs";
import { BsTools } from "react-icons/bs";
import { HiLocationMarker } from "react-icons/hi";
import { IoPersonAddOutline } from "react-icons/io5";
import { FaStar } from "react-icons/fa";

const LaMaisons = () => {
    const [language, setLanguage] = useState(localStorage.getItem('lan') || 'ar');

    const toggleLanguage = () => {
        const newLang = language === 'en' ? 'ar' : 'en';
        setLanguage(newLang);
        localStorage.setItem('lan', newLang);
    };

    const icons = [
        { icon: IoMdCall, label: language === 'ar' ? "اتصال" : "Call", link: "tel:00966597722440" },
        { icon: IoLogoWhatsapp, label: language === 'ar' ? "واتساب" : "WhatsApp", link: "https://wa.me/00966597722440" },
        { icon: TbMailFilled, label: language === 'ar' ? "البريد الإلكتروني" : "Email", link: "mailto:a.jamal@mmg.com.sa" },
        { icon: BsGlobe2, label: language === 'ar' ? "موقع الويب" : "Website", link: "https://www.lamaisons.net/" },
        { icon: BsTools, label: language === 'ar' ? "الصيانة" : "Maintenance", link: "https://www.lamaisons.net/maintenancerequest" },
        { icon: HiLocationMarker, label: language === 'ar' ? "الموقع" : "Location", link: "https://maps.app.goo.gl/wfSrWKgJoGqA5dLs5?g_st=com.google.maps.preview.copy" },
        { icon: FaStar, label: language === 'ar' ? "التقييم" : "Rate Us", link: "https://search.google.com/local/writereview?placeid=ChIJk9EDuObRwxURuQ3aLch6nTs" },
    ];
        

    const [expanded, setExpanded] = useState(false);
    const model = useRef();
    const handleClick = () => {
        setExpanded(true);
        saveToContacts()
        
        setTimeout(() => {
            setExpanded(false);
        }, 1000);
    };

    const contacts = [
        {
            image: male,
            name: "Ahmad Jamal Abdulkhalek",
            phone_number: "+966597722440",
            email: "a.jamal@mmg.com.sa",
            id: "1"
        },
    ];

    const [open, setOpen] = useState(false);

    const toggleDropdown = () => {
        setOpen(!open);
    };

    const openPDF = () => {
        const pdfPath = pdf;
        window.open(pdfPath, '_blank');
    };

    const handleDocumentClick = (e) => {
        if (model.current && !model.current.contains(e.target)) {
            setOpen(false);
        }
    };

    const saveToContacts = () => {
        const vcard =
            `BEGIN:VCARD\nVERSION:3.0\n` +
            `FN:Ahmad Jamal Abdulkhalek\n` +
            `TEL;CELL:+966597722440\n` +
            `EMAIL;HOME:a.jamal@mmg.com.sa\n` +
            `END:VCARD`;

        const blob = new Blob([vcard], { type: "text/vcard" });
        const url = URL.createObjectURL(blob);

        const downloadLink = document.createElement("a");
        downloadLink.href = url;
        downloadLink.download = `Ahmad.vcf`;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    };

    useEffect(() => {
        document.addEventListener("click", handleDocumentClick);
        return () => {
            document.removeEventListener("click", handleDocumentClick);
        };
    }, []);

    return (
        <div className='h-screen w-full flex flex-col gap-4 items-center bg-[#fff]'>
        <button onClick={toggleLanguage} className=" absolute top-4 z-40 right-4 bg-[#5B6976] text-white px-4 py-1 rounded-xl">
                {language === 'ar' ? 'EN' : 'AR'}
            </button>
            <div className='fixed w-screen h-screen -z-10 bg-[#fff]'></div>
            <div className='flex justify-center items-center bg-[#fff] relative z-30'>
                <img className='w-full h-full' src={logo} alt="La Maisons Logo" />
            </div>

            <div className='h-[30%] relative z-50 bg-[#fff] w-full'>
                <h2 className='text-center mb-0 font-bold text-2xl text-[#0B2A3D]'>
                {language === 'ar' ? 'أحمد جمال عبد الخالق' : 'Ahmad J. Abdulkhalek'}
                </h2>
                <h2 className='text-center mb-0 font-bold text-2xl text-[#B09B7B]'>
                
                
                {language === 'ar' ? 'مدير مكتب إدارة المشاريع' : 'PMO Manager'}
                </h2>
                <h2 className='text-center font-light text-md mb-7   mt-7'>
                {language === 'ar' ? 'التطوير العقاري' : 'Real Estate Development'}
                </h2>

                <div className='flex flex-col justify-around items-center bg-[#fff] '>
                    <div className='flex flex-col gap-3'>
                        {icons.map((item, index) => (
                            <a
                                key={index}
                                href={item.link}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="flex items-center bg-[#0A253D] px-3 py-1 w-[50vw] rounded-full shadow-xl"
                            >
                            {language === 'ar' ? null :   <item.icon className="w-6 h-6 text-white" />}
                              
                              
                                  
                                    {language === 'ar' ?
                                    <h2 className="flex-1 text-center  text-white text-[20px] font-bold">
                                  
                                  {item.label}
                                </h2>
                                    :      <h2 className="flex-1 text-center text-white text-[20px] font-bold">
                                  
                                    {item.label}
                                  </h2>}
                                
                                {language === 'ar' ?   <item.icon className="w-6 h-6 text-white" /> : null}

                            </a>
                        ))}
                    </div>

                    <div className='flex items-center justify-center mt-2' ref={model}>
                        <div className='w-[100%] relative'>
                        <div className='fixed bottom-12 right-4'>
            <button
                onClick={handleClick}
                className={`flex items-center bg-[#0A253D] text-white rounded-full shadow-md transition-all duration-300 ease-in-out
                    ${expanded ? 'pl-4 pr-6 py-2' : 'p-3'}
                `}
            >
            
                <IoPersonAddOutline size={24} className="transition-transform duration-300" />
                {expanded ?  <span
                    className={`ml-3 transition-opacity duration-300 ease-in-out
                        ${expanded ? 'opacity-100' : 'opacity-0 invisible'}
                    `}
                >
                                        {language === 'ar' ? 'إضافة إلى جهات الاتصال' : 'Add To Contacts'}
                </span>:''}
            </button> 
        </div>
                        </div>
                    </div>

                    <div className='mt-14 mb-2 '>
                        <a href="https://social.gomaksa.com/omar" className="cursor-pointer" target="_blank" rel="noopener noreferrer">
                            <img src={powered} alt="Powered By Goma" className='w-24 h-auto mx-auto' />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LaMaisons;
