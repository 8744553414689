import React, { useState, useEffect } from 'react';

const TypingAnimation = ({ text, onFinish,color,size }) => {
  const [displayedText, setDisplayedText] = useState('');
  const [index, setIndex] = useState(0);

  useEffect(() => {
    if (index < text.length) {
      const timer = setTimeout(() => {
        setDisplayedText((prev) => prev + text.charAt(index));
        setIndex((prev) => prev + 1);
      }, 10); // Typing speed
      return () => clearTimeout(timer);
    } else {
      onFinish(); // Notify when typing is done
    }
  }, [index, text, onFinish]);

  return (
    <span className="text-white text-[20px]" style={{
      color:color,
      fontSize:size
    }}>{displayedText}</span>
  );
};

export default TypingAnimation;
