/* eslint-disable */
import React, { useState, useEffect, useRef } from 'react';
import logo from '../assets/ganashbg.svg';
import powered from '../assets/POWEREDBY.svg';
import tiktok from '../assets/tiktok.png';
import male from '../assets/linkedin (2).png';
import pdf from '../pdf/asd.pdf';

// Importing icons from react-icons
import { MdEmail } from "react-icons/md";
import { BsTelephone } from "react-icons/bs";
import { IoMdCall } from "react-icons/io";
import { IoLogoWhatsapp } from "react-icons/io";
import { TbMailFilled } from "react-icons/tb";
import { BsGlobe2 } from "react-icons/bs";
import { BsTools } from "react-icons/bs";
import { HiLocationMarker } from "react-icons/hi";
import { IoPersonAddOutline } from "react-icons/io5";

const Ganache = () => {

    const icons = [
        { icon: IoMdCall, label: "Call", link: "tel:00966501020022" },
        { icon: TbMailFilled, label: "Email", link: "mailto:aaltir@agk-sa.com" },
    ];
    const [expanded, setExpanded] = useState(false);
    const handleClick = () => {
        setExpanded(true);
        saveToContacts()
        
        setTimeout(() => {
            setExpanded(false);
        }, 1000);
    };

    const contacts = [
        {
            image: male,
            name: "Abdulrahman Altir",
            phone_number: "+966501020022",
            email: "aaltir@agk-sa.com",
            id: "1"
        },
    ];

    const [open, setOpen] = useState(false);
    const model = useRef();

    const toggleDropdown = () => {
        setOpen(!open);
    };

    const openPDF = () => {
        const pdfPath = pdf;
        window.open(pdfPath, '_blank');
    };

    const handleDocumentClick = (e) => {
        if (model.current && !model.current.contains(e.target)) {
            setOpen(false);
        }
    };

    const saveToContacts = () => {
        const vcard =
            `BEGIN:VCARD\nVERSION:3.0\n` +
            `FN:Abdulrahman Altir\n` +
            `TEL;CELL:+966501020022\n` +
            `EMAIL;HOME:aaltir@agk-sa.com\n` +
            `END:VCARD`;

        const blob = new Blob([vcard], { type: "text/vcard" });
        const url = URL.createObjectURL(blob);

        const downloadLink = document.createElement("a");
        downloadLink.href = url;
        downloadLink.download = `Abdulrahman.vcf`;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    };

    useEffect(() => {
        document.addEventListener("click", handleDocumentClick);
        return () => {
            document.removeEventListener("click", handleDocumentClick);
        };
    }, []);

    return (
        <div className='max-w-[615px] '>
           
           
                <img className='w-screen h-screen z-10 -mt-10 ' src={logo} alt="La Maisons Logo" />
          <div className=' bg-[#DAD3CD] h-[25vh] w-screen max-w-[615px] fixed z-20 bottom-0 '></div>
          
         <div className='h-[30%] relative z-50  bg-transparent w-full -mt-[50vh]'>
                <h2 className='text-center mb-0 font-black text-3xl text-[#69473D]'>Abdulrahman Altir</h2>
                <h2 className='text-center mb-0 font-bold text-xl mt-3 text-[#69473D]'>Supply Chain Manager</h2>
                <h2 className='text-center font-light text-md mb-7   mt-7'></h2>

                <div className='flex flex-col justify-around items-center bg-transparent '>
                    <div className='flex flex-col gap-3'>
                        {icons.map((item, index) => (
                            <a
                                key={index}
                                href={item.link}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="flex items-center bg-[#69473D] px-3 py-1 w-[50vw] rounded-full shadow-xl"
                            >
                                <item.icon className="w-6 h-6 text-white" />
                                <h2 className="flex-1 text-center text-white text-[20px] font-bold">
                                    {item.label}
                                </h2>
                            </a>
                        ))}
                    </div>

                    <div className='flex items-center justify-center mt-2' ref={model}>
                        <div className='w-[100%] relative'>
                        <div className='fixed bottom-12 right-4'>
            <button
                onClick={handleClick}
                className={`flex items-center bg-[#69473D] text-white rounded-full shadow-md transition-all duration-300 ease-in-out
                    ${expanded ? 'pl-4 pr-6 py-2' : 'p-3'}
                `}
            >
            
                <IoPersonAddOutline size={24} className="transition-transform duration-300" />
                {expanded ?  <span
                    className={`ml-3 transition-opacity duration-300 ease-in-out
                        ${expanded ? 'opacity-100' : 'opacity-0 invisible'}
                    `}
                >
                    Add To Contacts
                </span>:''}
            </button>
        </div>
                            {open && (
                                <div id="dropdownHover" className="z-50 bg-[#2E2E2EEB] rounded-lg shadow w-[95%] absolute -top-28 left-4">
                                    <ul className="text-sm">
                                        {contacts.map((item) => (
                                            <div key={item.id} className='bg-[#2E2E2EEB] p-1 pl-5 items-center rounded-lg text-xs'>
                                                <div className='flex flex-row items-center gap-3 -ml-3 mb-3'>
                                                    <FaPerson size={20} color='white' />
                                                    <h2 className='text-[12px] text-[#fff] flex justify-end mr-1'>{item.name}</h2>
                                                </div>
                                                <div className='flex'>
                                                    <div className='border-l h-20 -mt-1.5'></div>
                                                    <div>
                                                        <div className='flex items-center' onClick={() => window.open(`tel:${item.phone_number}`)}>
                                                            <span className='border-b border-white w-5'></span>
                                                            <BsTelephone size={17} color='white' />
                                                            <button className='text-xs text-[#B7B7B7] flex justify-end ml-2 mr-3'>{item.phone_number}</button>
                                                        </div>
                                                        <div className='flex items-center mt-3.5'>
                                                            <span className='border-b border-white w-5 mt-1'></span>
                                                            <FaWhatsapp size={17} color='green' />
                                                            <button className='text-xs flex font-bold justify-end ml-1 text-[#B7B7B7]' onClick={() => window.open(`https://wa.me/${item.phone_number}`)}>
                                                                Whatsapp
                                                            </button>
                                                        </div>
                                                        <div className='flex items-center mt-3.5'>
                                                            <span className='border-b border-white w-5 mt-2 -ml-[0.5%]'></span>
                                                            <MdEmail size={17} color='white' />
                                                            <button className='text-xs font-bold flex justify-end ml-1 text-[#B7B7B7]' onClick={() => window.open(`mailto:${item.email}`)}>
                                                                Email
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </ul>
                                </div>
                            )}
                        </div>
                    </div>

                    <div className='mt-14 mb-2 '>
                        <a href="https://social.gomaksa.com/omar" className="cursor-pointer" target="_blank" rel="noopener noreferrer">
                            <img src={powered} alt="Powered By Goma" className='w-24 h-auto mx-auto' />
                        </a>
                    </div>
                </div>
            </div>
        
        </div>
    );
}

export default Ganache;
