/* eslint-disable */
import React, { useState , useEffect , useRef } from 'react'
import logo from '../assets/GOMA Logo GuideLines 2_page-0004.jpg'
import { MdEmail } from "react-icons/md";
import instagram from '../assets/instagram (2).png'

import powered from '../assets/PoweredbyGoma.png'

import tiktok from '../assets/tiktok.png'

import { FaPlus } from "react-icons/fa";
import { BsTelephone } from "react-icons/bs";
import { RiContactsFill } from "react-icons/ri";
import { FaPerson } from "react-icons/fa6";
import { FaWhatsapp } from "react-icons/fa6";
import { IoIosArrowDown } from "react-icons/io";
import male from '../assets/linkedin (2).png'
import pdf from '../pdf/asd.pdf'


const Ayman_Refaieah = () => {

  const icons = [
    {icon:instagram , email:"Instagram",link:"https://www.instagram.com/gomaksa?igsh=dGl4aWp2cGJ4dDI4&utm_source=qr"},
    {icon:tiktok , email:"Tiktok" , link:"https://www.tiktok.com/@gomaksa?_t=8ipVQrOEmJu&_r=1"},
  ]

   const array=[
{ image:male , name : "Ayman Refaieah", phone_number:"+966550109600" , email:"ayman.refaieah@gomaksa.com", id:"1"},
]

  const [open, setOpen]= useState(false)
  const model = useRef();


  function openedd(){
    setOpen(!open)
 }

 const openPDF = () => {
    // Replace 'your-pdf-file.pdf' with the actual path to your PDF file
    const pdfPath = pdf;

    // Open the PDF in a new window or tab
    window.open(pdfPath, '_blank');
  };

  const handleDocumentClick = (e) => {
    if (model.current && !model.current.contains(e.target)) {
      // Click occurred outside the SpeedDial, hide it
      setOpen(false);
    }
  };
  const saveToContacts = () => {
   
    const vcard = 
      `BEGIN:VCARD\nVERSION:3.0\n` +
      `FN:Ayman Refaieah\n` +
      `TEL;CELL:+966550109600\n` +
      `EMAIL;HOME:ayman.refaieah@gomaksa.com\n` +
      `END:VCARD`;

    const blob = new Blob([vcard], { type: "text/vcard" });
    const url = URL.createObjectURL(blob);
  
    // Create a hidden download link and trigger it
    const downloadLink = document.createElement("a");
    downloadLink.href = url;
    downloadLink.download = `Ayman.vcf`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };
   useEffect(() => {
    // Attach the event listener when the component mounts
    document.addEventListener("click", handleDocumentClick);

    // Detach the event listener when the component unmounts
    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);
  return (
    
    <div className='h-screen w-full flex flex-col gap-4 items-center bg-[#e5e7eb] '
    style={{backgroundImage:`url(${"image"})` ,
               backgroundPosition: "center",
               backgroundRepeat: "no-repeate",
               backgroundSize: "cover",
               position:'absolute',
               height: "100%",
               width:'100%',
               backgroundColor:"#e5e7eb",
               zIndex:-10,
               }}
   >
     <div className='fixed w-screen h-screen -z-10 bg-[#e5e7eb] '></div>
    <div className='w-44 h-[50%] flex justify-center items-center  bg-[#3f3f46] rounded-b-full relative z-50'>
    <div className='absolute font-semibold text-4xl top-14 -left-6 text-white'>
       <h2><span className='text-[#3f3f46]'>G</span>OMA</h2>
   </div>
   <div className='absolute -right-5 top-24'>
       <h2 className='text-sm text-[#3f3f46] -mr-2'><span className='text-white'>SMART SOLUTI</span>ONS</h2>
   </div>
   <div className='absolute bottom-3'>
   <img className='w-40 h-40 rounded-full custom-spin ' src={logo} />
</div>
    </div>
     
    <div className='h-[30%] relative z-50 bg-[#e5e7eb] w-full'>
    <h2 className='text-center mb-0 font-light'>Revolutionizing the future with innovative tech solutions and expertise </h2>
    <h2 className='text-center mb-4 font-bold'><button className='text-center mb-1 mt-2 border-2 border-black p-2 rounded-md' onClick={openPDF}>Company Profile</button></h2>
    <div className='flex flex-col justify-around items-center bg-[#e5e7eb]'>
       <div>
       <div className='flex flex-col gap-3 '>
      {icons.map((item) => {
     return(
      <div className='flex flex-row gap-3 items-center'>
         <a href={item.link} target ="_blank" className='flex flex-row gap-3 items-center'>
       <div className=' border border-black p-1.5 rounded-md '>
         <img className='w-6 h-6' src={item.icon} />
       </div>
       <h2 className='text-xs'>{item.email}</h2>
       </a>
       </div>
     )
    })}
     </div>
     </div>
<div className='flex items-center justify-center mt-2' ref={model}>
     <div className='w-[100%] relative' >
       <div className='flex justify-center items-center' >
     <button className=" rounded-lg w-[90%] flex items-center h-8   px-3" type="button"
onClick={openedd}
>
   <div className='flex flex-row items-center gap-3'>
   <RiContactsFill />
   <h2 className='text-md flex justify-end mr-3 whitespace-nowrap'>Contact us</h2>
   </div>
    <IoIosArrowDown className={`w-10 h-4 ${open?'rotate-180':''} `}/>
   
</button>
<button
       onClick={saveToContacts}
       className="fixed z-50 bottom-12 w-23 h-10 bg-[#3f3f46] rounded-3xl border-2 sm:right-72  font-bold text-sm right-2"
       style={{border: '2px solid'}}>
       <div className="flex flex-row gap-1 justify-center items-center text-center w-full">
         <div className="flex flex-col h-5 -mt-4 ml-2 text-white">
         <span className='text-xs '>Add To</span>
         <span className='text-xs '>Contacts</span>
         </div>
         <div className=" p-2 rounded-full">
           <FaPlus color='#fff'/>
         </div>
       </div>
     </button>
  </div>
  {open?
<div id="dropdownHover" className="z-50 bg-[#2E2E2EEB]  rounded-lg shadow w-[95%]  absolute -top-28 left-4">
   <ul class=" text-sm ">
   
      {
       array.map((item) => {
           return(
               <div key={item.id}  className='bg-[#2E2E2EEB] p-1 pl-5 items-center rounded-lg text-xs'>
                    <div className='flex flex-row items-center gap-3 -ml-3 mb-3'>
  <FaPerson size={20} color='white'/>

   <h2 className='text-[12px] text-[#fff] flex justify-end mr-1'>{item.name}</h2>
   </div>
   <div className='flex'>
       <div className='border-l h-20 -mt-1.5'> </div>
       <div>
       <div className='flex items-center' onClick={() => {
           window.open(`tel:${item.phone_number}`);
         }}>
           <span className='border-b border-white w-5'></span>
           <BsTelephone size={17} color='white'/>
   <button 
   className='text-xs text-[#B7B7B7] flex justify-end ml-2 mr-3'
   >{item.phone_number}
         </button>
       </div>
        <div className='flex items-center mt-3.5'>
           <span className='border-b border-white w-5 mt-1'></span>
           <FaWhatsapp size={17} color='green'/>
           <button
            className='text-xs flex font-bold justify-end ml-1 text-[#B7B7B7]'
            onClick={()=>{
           window.open(`https://wa.me/${item.phone_number}`)
         }}>
         Whatsapp
         </button>
       </div>
        <div className='flex items-center mt-3.5'>
           <span className='border-b border-white w-5 mt-2 -ml-[0.5%]'></span>
           <MdEmail size={17} color='white'/>
           <button
            className='text-xs font-bold flex justify-end ml-1 text-[#B7B7B7]'
             onClick={() => {
             window.open(`mailto:${item.email}`);
             }}>
         Email
         </button>
         
       </div>
       </div>
   </div>
               </div>
           )
       })
   }
    
   </ul>
</div>
:''}
   </div>
   </div>
     </div>
     <div className='mt-5'>
      
       <a href="https://www.gomaksa.com/" className="cursor-pointer" target="_blank">  <img src={powered} alt="Powerd By Goma" className='w-24 h-auto mx-auto '/></a>

       </div>
     </div>
     </div>
  )
}

export default Ayman_Refaieah
