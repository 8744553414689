
/* eslint-disable */
import React, { useState, useEffect, useRef } from 'react';
import logo from '../assets/NMM.png';
import powered from '../assets/11 PBGoma.svg';
import tiktok from '../assets/tiktok.png';
import male from '../assets/linkedin (2).png';
import pdf from '../pdf/asd.pdf';

// Importing icons from react-icons
import { MdEmail } from "react-icons/md";
import { BsTelephone } from "react-icons/bs";
import { IoMdCall } from "react-icons/io";
import { IoLogoWhatsapp } from "react-icons/io";
import { TbMailFilled } from "react-icons/tb";
import { BsGlobe2 } from "react-icons/bs";
import { BsTools } from "react-icons/bs";
import { HiLocationMarker } from "react-icons/hi";
import { IoPersonAddOutline } from "react-icons/io5";

const NawafـMـMalibari = () => {

    const icons = [
        { icon: IoMdCall, label: "Call", link: "tel:00966562699922" },
        { icon: IoLogoWhatsapp, label: "WhatsApp", link: "https://wa.me/+966562699922" },
        { icon: TbMailFilled, label: "Email", link: "mailto:Nawaf@mmg.com.sa" },
        { icon: BsGlobe2, label: "Website", link: "https://www.lamaisons.net/" },
        { icon: BsTools, label: "Maintenance", link: "https://www.lamaisons.net/maintenancerequest" },
        { icon: HiLocationMarker, label: "Location", link: "https://maps.app.goo.gl/wfSrWKgJoGqA5dLs5?g_st=com.google.maps.preview.copy" },
    ];
    const [expanded, setExpanded] = useState(false);
    const handleClick = () => {
        setExpanded(true);

        saveToContacts()
        setTimeout(() => {
            setExpanded(false);
        }, 1000);
    };

    const contacts = [
        {
            image: male,
            name: "Nawaf M. Malibari",
            phone_number: "+966562699922",
            email: "Nawaf@mmg.com.sa",
            id: "1"
        },
    ];

    const [open, setOpen] = useState(false);
    const model = useRef();

    const toggleDropdown = () => {
        setOpen(!open);
    };

    const openPDF = () => {
        const pdfPath = pdf;
        window.open(pdfPath, '_blank');
    };

    const handleDocumentClick = (e) => {
        if (model.current && !model.current.contains(e.target)) {
            setOpen(false);
        }
    };

    const saveToContacts = () => {
        const vcard =
            `BEGIN:VCARD\nVERSION:3.0\n` +
            `FN:Nawaf M. Malibari\n` +
            `TEL;CELL:+966562699922\n` +
            `EMAIL;HOME:Nawaf@mmg.com.sa\n` +
            `END:VCARD`;

        const blob = new Blob([vcard], { type: "text/vcard" });
        const url = URL.createObjectURL(blob);

        const downloadLink = document.createElement("a");
        downloadLink.href = url;
        downloadLink.download = `Nawaf.vcf`;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    };

    useEffect(() => {
        document.addEventListener("click", handleDocumentClick);
        return () => {
            document.removeEventListener("click", handleDocumentClick);
        };
    }, []);

    return (
        <div className='h-screen w-full flex flex-col gap-4 items-center bg-[#fff]'>
            <div className='fixed w-screen h-screen -z-10 bg-[#fff]'></div>
            <div className='flex justify-center items-center bg-[#fff] relative z-50'>
                <img className='w-full h-full' src={logo} alt="La Maisons Logo" />
            </div>

            <div className='h-[30%] relative z-50 bg-[#fff] w-full'>
                <h2 className='text-center mb-0 font-bold text-2xl text-[#0B2A3D]'>Nawaf M. Malibari</h2>
                <h2 className='text-center mb-0 font-bold text-2xl text-[#B09B7B]'>CEO</h2>
                <h2 className='text-center font-light text-md mb-7   mt-7'>Real Estate Development</h2>

                <div className='flex flex-col justify-around items-center bg-[#fff] '>
                    <div className='flex flex-col gap-3'>
                        {icons.map((item, index) => (
                            <a
                                key={index}
                                href={item.link}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="flex items-center bg-[#0A253D] px-3 py-1 w-[50vw] rounded-full shadow-xl"
                            >
                                <item.icon className="w-6 h-6 text-white" />
                                <h2 className="flex-1 text-center text-white text-[20px] font-bold">
                                    {item.label}
                                </h2>
                            </a>
                        ))}
                    </div>

                    <div className='flex items-center justify-center mt-2' ref={model}>
                        <div className='w-[100%] relative'>
                        <div className='fixed bottom-12 right-4'>
            <button
                onClick={handleClick}
                className={`flex items-center bg-[#0A253D] text-white rounded-full shadow-md transition-all duration-300 ease-in-out
                    ${expanded ? 'pl-4 pr-6 py-2' : 'p-3'}
                `}
            >
            
                <IoPersonAddOutline size={24} className="transition-transform duration-300" />
                {expanded ?  <span
                    className={`ml-3 transition-opacity duration-300 ease-in-out
                        ${expanded ? 'opacity-100' : 'opacity-0 invisible'}
                    `}
                >
                    Add To Contacts
                </span>:''}
            </button>
        </div>
                            {open && (
                                <div id="dropdownHover" className="z-50 bg-[#2E2E2EEB] rounded-lg shadow w-[95%] absolute -top-28 left-4">
                                    <ul className="text-sm">
                                        {contacts.map((item) => (
                                            <div key={item.id} className='bg-[#2E2E2EEB] p-1 pl-5 items-center rounded-lg text-xs'>
                                                <div className='flex flex-row items-center gap-3 -ml-3 mb-3'>
                                                    <FaPerson size={20} color='white' />
                                                    <h2 className='text-[12px] text-[#fff] flex justify-end mr-1'>{item.name}</h2>
                                                </div>
                                                <div className='flex'>
                                                    <div className='border-l h-20 -mt-1.5'></div>
                                                    <div>
                                                        <div className='flex items-center' onClick={() => window.open(`tel:${item.phone_number}`)}>
                                                            <span className='border-b border-white w-5'></span>
                                                            <BsTelephone size={17} color='white' />
                                                            <button className='text-xs text-[#B7B7B7] flex justify-end ml-2 mr-3'>{item.phone_number}</button>
                                                        </div>
                                                        <div className='flex items-center mt-3.5'>
                                                            <span className='border-b border-white w-5 mt-1'></span>
                                                            <FaWhatsapp size={17} color='green' />
                                                            <button className='text-xs flex font-bold justify-end ml-1 text-[#B7B7B7]' onClick={() => window.open(`https://wa.me/${item.phone_number}`)}>
                                                                Whatsapp
                                                            </button>
                                                        </div>
                                                        <div className='flex items-center mt-3.5'>
                                                            <span className='border-b border-white w-5 mt-2 -ml-[0.5%]'></span>
                                                            <MdEmail size={17} color='white' />
                                                            <button className='text-xs font-bold flex justify-end ml-1 text-[#B7B7B7]' onClick={() => window.open(`mailto:${item.email}`)}>
                                                                Email
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </ul>
                                </div>
                            )}
                        </div>
                    </div>

                    <div className='mt-14 mb-2 '>
                        <a href="https://social.gomaksa.com/omar" className="cursor-pointer" target="_blank" rel="noopener noreferrer">
                            <img src={powered} alt="Powered By Goma" className='w-24 h-auto mx-auto' />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default NawafـMـMalibari;
