import image from '../assets/logo.png'
import { FaWhatsapp } from "react-icons/fa";
import { MdPersonAddAlt1 } from "react-icons/md";
import insta from '../assets/pngwing.com.png'
// import { Document, Page,pdfjs } from 'react-pdf'; 
import React, { useEffect, useState ,useRef } from "react";
import { FaLocationDot } from "react-icons/fa6";
import Powerd from '../assets/PoweredbyGoma.png'
import { BsTelephoneFill } from "react-icons/bs";
import { FaYoutube } from "react-icons/fa6";
import { MdAlternateEmail } from "react-icons/md";
import { IoLogoGoogleplus } from "react-icons/io";
import { LuGithub } from "react-icons/lu";
import { FaSnapchatGhost } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";
import { CiInstagram } from "react-icons/ci";
import { FaXTwitter } from "react-icons/fa6";
import { FaFacebookF } from "react-icons/fa";
import { RiTiktokFill } from "react-icons/ri";
import { FaPinterestP } from "react-icons/fa";
import { FaTelegramPlane } from "react-icons/fa";
import pdf from '../pdf/alpha.pdf'

function AlphaPack() {


 const openPDF = () => {

    const pdfPath = pdf;

    window.open(pdfPath, '_blank');
  };


  const icons = [
    {icon:<FaXTwitter size={30}/>},
    {icon:<FaFacebookF size={30}/>},
    {icon:<CiInstagram size={30}/>},
    {icon:<RiTiktokFill size={30}/>},
    {icon:<FaLinkedinIn size={30}/>},
    {icon:<FaSnapchatGhost size={30}/>},
    {icon:<FaYoutube size={30}/>},
    {icon:<FaWhatsapp size={30}/>},
    {icon:<LuGithub  size={30}/>},
    {icon:<IoLogoGoogleplus size={30}/>},
    {icon:<FaPinterestP  size={30}/>},
    {icon:<FaTelegramPlane  size={30}/> },
  ]

  const iconn =[
    {iconn:<BsTelephoneFill size={12}/> ,data:"+966565655836" ,  },
    {iconn:<MdAlternateEmail size={12}/> , data:"Hisham@alphapacksa.com"},
    {iconn:<FaLocationDot size={12}/> , data:"saudi arabia"},
  ]

  const handleWhatsAppShare = () => {
    // Replace 'your-website-url' with the actual URL you want to share
    const shareUrl = "ads";

    // Construct the WhatsApp share link
    const whatsappLink = `https://wa.me/?text=${encodeURIComponent(shareUrl)}`;
   
    // Open the link in a new tab/window
    window.open(whatsappLink, '_blank');
  }


  const saveToContacts = () => {
    
    const vcard = 
      `BEGIN:VCARD\nVERSION:3.0\n` +
      `N:Wazzan;;;\n` +
      `FN:Hisham\n` +
      `TEL;CELL:+966565655836\n` +
      `EMAIL;HOME:Hisham@alphapacksa.com\n` +
      `ADR;HOME:Jeddah, Saudi Arabia\n` +
      `ORG;WORK:Alpha Pack\n` +
      `END:VCARD`;
  
    // Create a Blob from the vCard string
    const blob = new Blob([vcard], { type: "text/vcard" });
    const url = URL.createObjectURL(blob);
  
    // Create a hidden download link and trigger it
    const downloadLink = document.createElement("a");
    downloadLink.href = url;
    downloadLink.download = `Alpha_Pack.vcf`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };



  
  return (
    <div className="min-h-screen bg-[#e5e7eb] relative pb-20 overflow-hidden">
      <div className='fixed h-screen w-screen bg-[#e5e7eb] -z-10 bottom-0 top-0 right-0 left-0'></div>
      <button
        onClick={saveToContacts}
        className='fixed z-50 bottom-2 w-auto h-auto p-1 bg-[var(--button-bg-color)] rounded-md text-[var(--button-text-color)] font-bold text-lg right-2'>
        <div className='flex flex-col text-center p-1'>
          <MdPersonAddAlt1 size={36}/>
        </div>  
        
      </button>
      <div className='h-auto w-24 mx-auto -bottom-1 absolute left-0 right-0 '>
      <a href="https://www.gomaksa.com/" className="cursor-pointer" target="_blank"> <img src={Powerd} alt="" /></a>


</div>

      

      <div className='relative h-80'>
        <div className='bg-[#ffff] h-64'>
  <div
    style={{
      backgroundImage: `url(${image})`,
      backgroundPosition: "center",
      backgroundSize: "100%",
      backgroundRepeat: "no-repeat",  // Corrected spelling here
      height: "255px",
    }}
  ></div>
</div>
      {/* <div className='flex justify-center items-center -mt-10 '>
       <img src={image} className='w-[100px] h-[100px] rounded-md bg-white border-4 border-black'/>
      </div> */}
</div>

      <div className='flex flex-col justify-center items-center text-center text-black -mt-10 relative z-20'>
        <h2 className='text-2xl 2sm:text-3xl  z-20 w-full font-bold'>Hisham Wazzan</h2>
        <h2 className='w-[70%] text-sm md:text-lg font-semibold '>Sales manager</h2>
        </div>
             <h2 className='text-center mb-4 font-bold'><button className='text-center mb-3 mt-5 border-2 border-black p-3 rounded-md text-black' onClick={openPDF}>Company Profile</button></h2>
       <div className='flex justify-center mt-4'>
  <div className='item-center text-center text-black'>
      <div className='flex items-center gap-2 p-3' 
      onClick={() => {
            window.open(`tel:+966565655836`);
          }}>
        <button className='border-2 p-1 border-black rounded-full text-[30px] md:text-[30px]'>
          <BsTelephoneFill size={20}/>
        </button>
        <p>+966565655836</p>
      </div>
      <div className='flex items-center gap-2 p-3'
      onClick={() => { 
            window.open(`mailto:Hisham@alphapacksa.com`);
          }}
      >
        <button className='border-2 p-1 border-black rounded-full text-[30px] md:text-[30px]'>
          <MdAlternateEmail size={20}/>
        </button>
        <p>Hisham@alphapacksa.com</p>
      </div>
      <div className='flex items-center gap-2 p-3'
      onClick={() => { 
            window.location.href = 'https://goo.gl/maps/5G5FsBvjgNbxrCFB9';
          }}>
        <button className='border-2 p-1 border-black rounded-full text-[30px] md:text-[30px]'>
          <FaLocationDot size={20}/>
        </button>
        <p>Jeddah, Saudi Arabia</p>
      </div>
       <div className='flex items-center gap-2 p-3'
      onClick={() => { 
            window.location.href = 'https://www.instagram.com/alphapack_sa?igsh=Z3NqaG8wZGNkbjE=';
          }}>
        <button className='-ml-1 text-[30px] md:text-[30px]'>
          <img src={insta} alt="" className='w-10 h-auto' />
        </button>
        <p>@Alpha Pack Company</p>
      </div>
  </div>
</div>
      </div>

);
}

export default AlphaPack;
