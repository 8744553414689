import React from "react";
import animation from '../animation_lmc4mg0l.json'

import logo from '../assets/godiva.svg'
import Lottie from "lottie-react";
import { FaFacebookF } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaTiktok } from "react-icons/fa";
import { SiGooglemaps } from "react-icons/si";
import { BsCupHot } from "react-icons/bs";
import { IoCartOutline } from "react-icons/io5";


function Godiva_location() {
  return (
    <div className=" w-screen h-screen  top-0 left-0 right-0 z-20 bg-gradient-to-r    from-[#3C3C3C] via-[#3C3C3C] to-[#3C3C3C] flex flex-col">
    <div>
     <div className=" fixed  w-screen h-screen  top-0 left-0 right-0 -z-10   bg-[#3C3C3C]"></div>
            <div className=" mb-5 mt-7 top-5 right-0 left-0  z-30 flex flex-col gap-2 justify-center text-white items-center">
              <div className="bg-white rounded-full p-3 py-4">
              <img src={logo} />
              </div>
              <h2 className="font-bold">G O D I V A . K S A</h2>
              <h2 className="flex justify-center items-center w-60 text-center text-[13px] text-gray-400">Welcome to GODIVA. K S A 🇸🇦 Select the destination you'd like to explore ✨
              </h2>
            </div>
        <div className="z-40 gap-4 flex flex-col justify-center items-center text-white  top-1/3 w-screen">
        
        <div className=" gap-3 bg-black p-3 md:w-[35%] sm:w-[50%] w-[80%] rounded-lg shadow-xl">
        <a href="https://qr.finedinemenu.com/godiva-cafe-4?table=sample" target="_blank" className="relative flex ">
        <BsCupHot size={30} className="ml-4" />
          <h2 className="absolute top-1 left-[38%]">Godiva Caffe Menu</h2>
        </a>
      </div>

      <div className=" gap-3 bg-black p-3 md:w-[35%] sm:w-[50%] w-[80%] rounded-lg shadow-xl">
      <a href="https://godiva.com.sa/" target="_blank" className="relative flex ">
      <IoCartOutline size={30} className="ml-4" />
        <h2 className="absolute top-1 left-[38%]">Godiva Website</h2>
      </a>
    </div>

        <div className=" gap-3 bg-black p-3 md:w-[35%] sm:w-[50%] w-[80%] rounded-lg shadow-xl">
            <a href="https://www.facebook.com/GodivaArabia?mibextid=LQQJ4d" target="_blank" className="relative flex ">
            <FaFacebookF size={30} className="ml-4" />
              <h2 className="absolute top-1 left-[43%]">Facebook</h2>
            </a>
          </div>


           <div className="gap-3 bg-black p-3 md:w-[35%] sm:w-[50%] w-[80%] rounded-lg shadow-xl">
            <a href="https://www.instagram.com/godivaksa/?igshid=MzRlODBiNWFlZA%3D%3D" target="_blank" className="relative flex">
            <FaInstagram size={30} className="ml-4" />
              <h2 className="absolute top-1 left-[43%]">Instagram</h2>
            </a>        
            </div>
           <div className="gap-3 bg-black p-3 md:w-[35%] sm:w-[50%] w-[80%] rounded-lg shadow-xl">
               <a href="https://www.tiktok.com/@godivaksa?_t=8fnCvCtAMS2&_r=1" target="_blank" className="relative flex">
               <FaTiktok size={30} className="ml-4" />

              <h2 className="absolute top-1 left-[43%]">Tiktok</h2>
            </a>  
          </div>
          {/**  <div className="gap-3 bg-black p-3 md:w-[35%] sm:w-[50%] w-[80%] rounded-lg shadow-xl">
               <a href="" className="relative">
              <img className="ml-4" src={gmail} />
              <h2 className="absolute top-2 left-[43%]">Gmail</h2>
            </a>  
          </div>  */}
          
           <div className="gap-3 bg-black p-3 md:w-[35%] sm:w-[50%] w-[80%] rounded-lg shadow-xl mb-10">
              <a href="https://search.google.com/local/writereview?placeid=ChIJ0WyydE3FwxURtTpj3dSlhwQ" target="_blank" className="relative flex">
              
              <SiGooglemaps size={30} className="ml-4" />
              <h2 className="absolute top-1 left-[43%]">Rate us</h2>
              <Lottie className='w-14 h-14 rotate-180 absolute -top-2 md:left-[65%] left-[70%]' animationData={animation} />
            </a>
          </div>
         
        </div>


        </div>
      
                   <div className="font-semibold  w-screen flex justify-center"> <h2 className=" text-xs text-gray-300 cursor-pointer">
             <a href="https://www.gomaksa.com/" className="cursor-pointer" target="_blank">Powered by Goma</a></h2></div>
       
    </div>
  );
}

export default Godiva_location;
