
import React from 'react';
import logo from '../assets/godiva.png';
import Lottie from 'lottie-react';
import { FaFacebookF, FaInstagram, FaTiktok } from 'react-icons/fa';
import { SiGooglemaps } from 'react-icons/si';
import { BsCupHot } from 'react-icons/bs';
import { IoCartOutline } from 'react-icons/io5';
 import animation from '../animation_lmc4mg0l.json'

function roshana() {
  const gradientStyle = {
    background: 'linear-gradient(130deg, rgba(36,37,42,1) 0%, rgba(57,58,66,1) 100%)',
  };

  return (
    <div className="w-screen h-screen flex flex-col justify-between" >
    <div className=" fixed  w-screen h-screen  top-0 left-0 right-0 bottom-0 -z-10" style={gradientStyle}></div>
    <header className="text-center p-5">
    <div className="inline-block rounded-full p-6 py-10 bg-white">
      <img src={logo} alt="Godiva Logo" className="block w-28 " />
    </div>
    <h1 className="text-white text-2xl font-bold mt-2">GODIVA.KSA</h1>
    <p className="text-gray-400">Welcome to GODIVA. K S A 🇸🇦 Select the destination you'd like to explore ✨</p>
  </header>
  

      <main className="mb-auto">
        <div className="flex flex-col items-center gap-4">
          {/* Buttons go here */}
         
          
        <div className=" gap-3 bg-black p-3 md:w-[35%] sm:w-[50%] w-[80%] rounded-lg shadow-xl">
        <a href="https://qr.finedinemenu.com/yQVFpH0Pz?table=sample" target="_blank" className="relative flex text-white ">
        <BsCupHot size={30} className="ml-4" />
          <h2 className="absolute top-1 left-[38%] text-[13px] text-white">Godiva Caffe Menu</h2>
        </a>
      </div>

      <div className=" gap-3 bg-black p-3 md:w-[35%] sm:w-[50%] w-[80%] rounded-lg shadow-xl ">
      <a href="https://godiva.com.sa/" target="_blank" className="relative flex text-white">
      <IoCartOutline size={30} className="ml-4" />
        <h2 className="absolute top-1 left-[38%] text-[13px]">Godiva Website</h2>
      </a>
    </div>

    <div className="gap-3 bg-black p-3 md:w-[35%] sm:w-[50%] w-[80%] rounded-lg shadow-xl">
    <a href="https://www.tiktok.com/@godivaksa?_t=8fnCvCtAMS2&_r=1" target="_blank" className="relative flex text-white">
    <FaTiktok size={30} className="ml-4" />

   <h2 className="absolute top-1 left-[32%] text-[13px]"> Follow Us On | Tiktok</h2>
 </a>  
</div>



<div className="gap-3 bg-black p-3 md:w-[35%] sm:w-[50%] w-[80%] rounded-lg shadow-xl">
<a href="https://www.instagram.com/godivaksa/?igshid=MzRlODBiNWFlZA%3D%3D" target="_blank" className="relative flex text-white">
<FaInstagram size={30} className="ml-4" />
  <h2 className="absolute top-1 left-[32%] text-[13px]"> Follow Us On | Instagram</h2>
</a>        
</div>



        <div className=" gap-3 bg-black p-3 md:w-[35%] sm:w-[50%] w-[80%] rounded-lg shadow-xl">
            <a href="https://www.facebook.com/GodivaArabia?mibextid=LQQJ4d" target="_blank" className="relative flex text-white">
            <FaFacebookF size={30} className="ml-4" />
              <h2 className="absolute top-1 left-[32%] text-[13px]">Follow Us On | Facebook</h2>
            </a>
          </div>


          
          
          {/**  <div className="gap-3 bg-black p-3 md:w-[35%] sm:w-[50%] w-[80%] rounded-lg shadow-xl">
               <a href="" className="relative">
              <img className="ml-4" src={gmail} />
              <h2 className="absolute top-2 left-[43%]">Gmail</h2>
            </a>  
          </div>  */}
          
           <div className="gap-3 bg-black p-3 md:w-[35%] sm:w-[50%] w-[80%] rounded-lg shadow-xl mb-10">
              <a href="https://search.google.com/local/writereview?placeid=ChIJ0WyydE3FwxURtTpj3dSlhwQ" target="_blank" className="relative flex text-white">
              
              <SiGooglemaps size={30} className="ml-4" />
              <h2 className="absolute top-1 left-[43%] text-[13px]">Rate us</h2>
              <Lottie className='w-14 h-14 rotate-180 absolute -top-2 md:left-[65%] left-[70%]' animationData={animation} />
            </a>
          </div>


        </div>
      </main>

      <footer className="text-center p-3 text-xs text-gray-300">
        <a href="https://www.gomaksa.com/" target="_blank" rel="noreferrer" className="hover:underline">
          Powered by Goma
        </a>
      </footer>
    </div>
  );
}

export default roshana;

