import React from 'react'
import { FaWhatsapp } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { CgWebsite } from "react-icons/cg";
import logo from '../assets/ALBNFSJ_LOGO-1-removebg.png'
import { Link } from 'react-router-dom';
import powered from '../assets/PoweredbyGoma.png'
import { FaSnapchat } from "react-icons/fa6";
import { SiGooglemaps } from "react-icons/si";
import Lottie from 'lottie-react';
import animation from '../animation_lmc4mg0l.json'
import { FaTiktok } from "react-icons/fa";

function salon_albnfsj() {
 
    return (
        <div className='flex flex-col justify-center gap-20 items-center h-screen bg-[#A9A9A9]'>
        <div className=" fixed  w-screen h-screen  top-0 left-0 right-0 bottom-0 -z-10   bg-[#A9A9A9]"></div>
    
            <div className='flex flex-col justify-center items-center mt-24'>
            
                 <div className=" w-56 h-56 rounded-full p-3 py-4 mt-10  bg-[#181818] flex justify-center items-center"
                 style={{ boxShadow: "10px 40px 70px rgba(0, 0, 0, 0.1)",}}>
                 <img className='' src={logo} />
                 </div>
                  <h2 className=' text-xl font-bold mt-3'>صالون البنفسج</h2>
                <h2 className='text-sm text-center w-[80%] flex flex-col font-bold mt-7 -mb-10'>
                <span >: أوقات الدوام</span> 
                <span>  03:30 pm - 10:00 pm </span>
              </h2>
                 </div>
    
                <div className='grid grid-cols-1 md:grid-cols-2 text-black w-screen'>


                <a href='https://www.tiktok.com/@salon_albnfsj?_t=8lkJAjrss8v&_r=1' target='_blank'>

  
                <div className='flex justify-between mr-1 rounded-lg p-3 bg-[#fff] relative m-2 w-[95%]'>
                        <div className=' flex items-center'>
                            <div className='absolute text-xl left-4'><FaTiktok size={30}/></div></div>
                        <div className='flex justify-center items-center text-center w-full'>
                        <h2 className='font-semibold text-sm'>Tiktok</h2>
                        </div>
                </div>
                </a>



            <a href='https://www.instagram.com/salon_albnfsj?igsh=ZnZ2OG90aXp3c3Fu' target='_blank'>

  
            <div className='flex justify-between mr-1 rounded-lg p-3 bg-[#fff] relative m-2 w-[95%]'>
                    <div className=' flex items-center'>
                        <div className='absolute text-xl left-4'><FaInstagram size={30}/></div></div>
                    <div className='flex justify-center items-center text-center w-full'>
                    <h2 className='font-semibold text-sm'>Instagram</h2>
                    </div>
            </div>
            </a>
    
            <a href='https://www.snapchat.com/add/salon_albnfsj' target='_blank'>
             <div className='flex justify-between mr-1 rounded-lg p-3 bg-[#fff] relative m-2 w-[95%]'>
                    <div className=' flex items-center'>
                        <div className='absolute text-xl left-4'><FaSnapchat size={30}/></div></div>
                    <div className='flex justify-center items-center text-center w-full'>
                    <h2 className='font-semibold text-sm'>Snapchat</h2>
                    </div>
            </div>
            </a>
    
            <button
            onClick={() => {
        const phoneNumber = "966566615560"; // Replace with your specific phone number
        const cleanedPhoneNum = phoneNumber.replace(/\s+/g, ""); // Remove all spaces
        window.open(`https://wa.me/${cleanedPhoneNum}`);
      }}
            >
             <div className='flex justify-between rounded-lg p-3 bg-[#fff] relative m-2 w-[95%]'>
                    <div className=' flex items-center'>
                        <div className='absolute text-xl left-4'><FaWhatsapp size={30}/></div></div>
                    <div className='flex justify-center items-center text-center w-full'>
                    <h2 className='font-semibold text-sm'>Whatsapp</h2>
                    </div>
            </div> 
            </button>
<div className='flex justify-center  w-[96%]'>
            <div className="ga- bg-white p-2  rounded-lg shadow-xl mb-10 ml-2 mt-3 w-[100%]">
            <a href="https://maps.app.goo.gl/dRtMYk8hPAHesWcDA" target="_blank" className="relative flex">
            
            <SiGooglemaps color='#000' size={30} className=" ml-1" />
            <h2 className="absolute top-1 left-[46%] text-black font-semibold text-sm">Maps</h2>
            <Lottie color='#000' className='w-14 h-14 rotate-180 absolute  -top-2 md:left-[65%] left-[70%] text-black lottie-animation' animationData={animation} />
          </a>
        </div>
        </div>


          </div>
          <a href="https://www.gomaksa.com/" className="cursor-pointer" target="_blank"> <img src={powered} alt="Powerd By Goma" className='w-24 h-auto mx-auto '/></a>
    
        </div>
    
      )
    }
    

export default salon_albnfsj
