import {
  BrowserRouter as Router,
  Routes,
  Route,
  useParams,
} from "react-router-dom";
import pageOne from "./Pages/pageOne";
import Omar from "./Pages/Omar";
import AlphaPack from "./Pages/AlphaPack";
import Godiva_location from "./Pages/Godiva-location";
import goma_designer from "./Pages/goma_designer";
import Template9 from "./Pages/goma_designer";
import salon_albnfsj from "./Pages/salon_albnfsj";
import roshana from "./Pages/roshana";
import imc from "./Pages/imc";
import redseamal from "./Pages/redseamal";
import Takhassusi from "./Pages/Takhassusi";
import DallahHospital from "./Pages/DallahHospital";
import Goma_tech from "./Pages/Goma_tech";
import Ayman_Refaieah from "./Pages/Ayman_Refaieah";
import Hussain_sabah from "./Pages/Hussain_sabah";
import Wazzan_RESTAURANT from "./Pages/Wazzan_RESTAURANT";
import La_maisons from "./Pages/La_maisons";
import NawafـMـMalibari from "./Pages/NawafـMـMalibari";
import Mohmaed_M_Elsayed from "./Pages/Mohmaed_M_Elsayed";
import Ganache from "./Pages/Ganache";
import AhmadAbdulkhalekv2 from "./Pages/AhmadAbdulkhalekv2";
import AhmadAbdulkhalekv3 from "./Pages/AhmadAbdulkhalekv3";
import Lewan from "./Pages/Lewan";


// import PageTwo from './Pages/PageTwo';

function App() {
  return (
    <Router>
      <Routes>
        {/**goma private */}
        <Route path="/abdulrazaq" Component={pageOne}></Route>
        <Route path="/omar" Component={Omar}></Route>
        <Route path="/Ayman_Refaieah" Component={Ayman_Refaieah}></Route>
    
        <Route path="/Hussain_sabah" Component={Hussain_sabah}></Route>


    
        <Route path="/ganache" Component={Ganache}></Route>

        <Route path="/WAZZAN_RESTAURANT" Component={Wazzan_RESTAURANT}></Route>


        <Route path="/lewan" Component={Lewan}></Route>
        <Route path="/la_maisons" Component={La_maisons}></Route>
     {/** <Route path="/AhmadAbdulkhalekv2" Component={AhmadAbdulkhalekv2}></Route>
        <Route path="/AhmadAbdulkhalekv3" Component={AhmadAbdulkhalekv3}></Route>
*/}   
        <Route path="/NawafـMـMalibari" Component={NawafـMـMalibari}></Route>
        <Route path="/Mohmaed_M_Elsayed" Component={Mohmaed_M_Elsayed}></Route>
        {/**alpha */}
         
        <Route path="/alpha" Component={AlphaPack}></Route>

        {/**goma public */}
        <Route path="/goma_designer" Component={goma_designer}></Route>
        <Route path="/goma_tech" Component={Goma_tech}></Route>
        {/**salon albnfsj */}
        <Route path="/salon_albnfsj" Component={salon_albnfsj}></Route>
        {/**godiva */}
        <Route path="/Godiva_cafe" Component={Godiva_location}></Route>
        <Route path="/roshana" Component={roshana}></Route>
        <Route path="/imc" Component={imc}></Route>
        <Route path="/redseamall" Component={redseamal}></Route>
        <Route path="/Takhassusi" Component={Takhassusi}></Route>
        <Route path="/DallahHospital" Component={DallahHospital}></Route>
      </Routes>
    </Router>
  );
}

export default App;
